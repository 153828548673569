<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout id="components-layout-demo-custom-trigger">
        <Sider />
        <a-layout class="section">
          <a-layout-content
            :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }"
          >
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header
                    style="border-bottom: 1px solid rgb(235, 237, 240)"
                    title="海报管理"
                  >
                    <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template>
                  </a-page-header>
                </div>
                <a-table
                  :columns="columns"
                  :data-source="posterList"
                  :pagination="false"
                  :scroll="{ x: 500, y: table_h }"
                  :row-key="(record) => record.id"
                  size="middle"
                  ref="tableList"
                >
                  <span slot="btns" slot-scope="btns, num">
                    <a-button
                      class="editable-add-btn"
                      v-for="btn in btns"
                      :key="btn"
                      @click="handel(btn, num)"
                      size="small"
                      style="margin: 5px"
                    >
                      {{ btn }}
                    </a-button>
                  </span>
                </a-table>
                <!-- <div ref="pagination">
            <a-pagination
            v-if="pageshow"
            :default-current="currentPage"
            :defaultPageSize="defaultPageSize"
            :page-size-options="pageSizeOptions"
            show-size-changer
            show-quick-jumper
            :total="total_num"
            :show-total="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="onPageChange"
            >
            <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
            </template>
            </a-pagination>
        </div> -->
                <a-modal
                  v-model="visible"
                  :title="modelTitle"
                  :confirm-loading="confirmLoading"
                  @ok="handleOk"
                  ok-text="确认"
                  cancel-text="取消"
                >
                  <a-form :form="addform" @submit="handleSubmit">
                    <a-form-item label="海报名称：">
                      <a-input
                        v-decorator="[
                          'title',
                          {
                            rules: [
                              { required: true, message: '请输入海报名称!' },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="排序：">
                      <a-input-number
                        v-decorator="[
                          'sequence',
                          {
                            rules: [{ required: true, message: '请输入排序!' }],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-col :span="24" class="title">海报模板:</a-col>
                    <a-form-item label="图片" extra="最多支持1张">
                      <a-input
                        v-decorator="[
                          'template.url',
                          {
                            rules: [{ required: true, message: '请选择图片!' }],
                          },
                        ]"
                        type="hidden"
                      ></a-input>
                      <a-upload
                        name="file"
                        list-type="picture"
                        class="upload-list-inline"
                        :fileList="fileList"
                        :customRequest="changeImg"
                        @change="getchangeImg"
                        :remove="removeImg"
                        :beforeUpload="handleBeforeUpload"
                      >
                        <a-button :disabled="!uploadBtn">
                          <a-icon type="upload" /> 上传图片
                        </a-button>
                      </a-upload>
                    </a-form-item>
                    <a-col :span="10">
                      <a-form-item label="宽度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'template.size.w',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入宽度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="高度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'template.size.h',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入高度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" class="title">邀请码:</a-col>
                    <a-col :span="10">
                      <a-form-item label="X轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'invitation_code_parameter.position.x',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入X轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="Y轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'invitation_code_parameter.position.y',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入Y轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="字体大小（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'invitation_code_parameter.font.size',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入字体大小!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="颜色">
                        <a-input
                          v-decorator="[
                            'invitation_code_parameter.font.color',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择颜色!',
                                },
                              ],
                            },
                          ]"
                        />
                        <colorPicker
                          v-model="color"
                          v-on:change="headleChangeColor"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="是否加粗">
                        <a-radio-group
                          v-decorator="[
                            'invitation_code_parameter.font.isBold',
                            {
                              rules: [
                                { required: true, message: '请选择是否加粗!' },
                              ],
                            },
                          ]"
                        >
                          <a-radio :value="1"> 加粗 </a-radio>
                          <a-radio :value="0"> 不加粗 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" class="title">昵称:</a-col>
                    <a-col :span="10">
                      <a-form-item label="X轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'nickname_parameter.position.x',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入X轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="Y轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'nickname_parameter.position.y',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入Y轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="字体大小（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'nickname_parameter.font.size',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入字体大小!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="颜色">
                        <a-input
                          v-decorator="[
                            'nickname_parameter.font.color',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择颜色!',
                                },
                              ],
                            },
                          ]"
                        />
                        <colorPicker
                          v-model="color1"
                          v-on:change="headleChangeColor1"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="是否加粗">
                        <a-radio-group
                          v-decorator="[
                            'nickname_parameter.font.isBold',
                            {
                              rules: [
                                { required: true, message: '请选择是否加粗!' },
                              ],
                            },
                          ]"
                        >
                          <a-radio :value="1"> 加粗 </a-radio>
                          <a-radio :value="0"> 不加粗 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" class="title">二维码:</a-col>
                    <a-col :span="10">
                      <a-form-item label="宽度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'qr_code_parameter.size.w',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入宽度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="高度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'qr_code_parameter.size.h',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入高度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="X轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'qr_code_parameter.position.x',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入X轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="Y轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'qr_code_parameter.position.y',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入Y轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" class="title">头像:</a-col>
                    <a-col :span="10">
                      <a-form-item label="宽度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'avatar_parameter.size.w',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入宽度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="高度（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'avatar_parameter.size.h',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入高度!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10">
                      <a-form-item label="X轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'avatar_parameter.position.x',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入X轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="10" :offset="2">
                      <a-form-item label="Y轴定位（px）">
                        <a-input-number
                          :precision="0"
                          step="1"
                          :min="0"
                          v-decorator="[
                            'avatar_parameter.position.y',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入Y轴定位!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                  </a-form>
                </a-modal>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {
      color: "",
      color1: "",
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "海报名称",
          key: "title",
          dataIndex: "title",
          align: "center",
        },
        {
          title: "海报排序",
          key: "sequence",
          dataIndex: "sequence",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      posterList: [],
      canUpload:''
    };
  },
  components: {
    Header,
    Sider,
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight - this.$refs.header.offsetHeight - 45;
      // this.$refs.pagination.offsetHeight -
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //更滑分类颜色
    headleChangeColor(e) {
      this.addform.setFieldsValue({
        invitation_code_parameter: {
          font: {
            color: e,
          },
        },
      });
    },
    //更滑分类颜色
    headleChangeColor1(e) {
      this.addform.setFieldsValue({
        nickname_parameter: {
          font: {
            color: e,
          },
        },
      });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/invitationPoster/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var posterList = res.data.data;
            for (var i = 0; i < posterList.length; i++) {
              posterList[i].btns = ["编辑", "删除"];
            }
            that.changeTableH();
            that.posterList = posterList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.addform.setFieldsValue({
            template: {
              url: img,
            },
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file){
      if(file.type.indexOf("image")<0){
      this.$message.error("仅支持上传图片")
      this.canUpload=false
      return false;
      }else{
      this.canUpload=true
        return true
      }
    },
    getchangeImg(info) {
      if(!this.canUpload){
        info.fileList=[]
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.addform.setFieldsValue({
            template_url: "",
          });
        }
      }
    },
    //新增海报
    handleAdd() {
      this.visible = true;
      this.modelTitle = "新增海报";
      this.id = "";
      this.uploadBtn = true;
      this.fileList = [];
      var that = this;
      that.$nextTick(() => {
        that.addform.setFieldsValue({
          title: "",
          template: {
            url: "",
            size: {
              w: "",
              h: "",
            },
          },
          sequence: "",
          nickname_parameter: {
            font: {
              size: "",
              color: "",
              isBold: "",
            },
            position: {
              x: "",
              y: "",
            },
          },
          invitation_code_parameter: {
            font: {
              size: "",
              color: "",
              isBold: "",
            },
            position: {
              x: "",
              y: "",
            },
          },
          avatar_parameter: {
            size: {
              w: "",
              h: "",
            },
            position: {
              x: "",
              y: "",
            },
          },
          qr_code_parameter: {
            size: {
              w: "",
              h: "",
            },
            position: {
              x: "",
              y: "",
            },
          },
        });
      });
    },
    //新增、编辑提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          if (!that.id) {
            that.axios
              .post("/admin/invitationPoster/create", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          } else {
            data.id = that.id;
            that.axios
              .post("/admin/invitationPoster/update", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },

    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
      var data;
      that.id = num.id;
      if (btn == "编辑") {
        data = {
          id: num.id,
        };
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.axios
          .get("/admin/invitationPoster/one", { params: data })
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.uploadBtn = false;
              that.fileList = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.template.url,
                  thumbUrl: res.data.data.template.url,
                },
              ];
              that.visible = true;
              that.modelTitle = "编辑海报";
              that.$nextTick(() => {
                that.addform.setFieldsValue({
                  title: res.data.data.title,
                  template: {
                    url: res.data.data.template.url,
                    size: {
                      w: res.data.data.template.size.w,
                      h: res.data.data.template.size.h,
                    },
                  },
                  template_url: res.data.data.template_url,
                  sequence: res.data.data.sequence,
                  nickname_parameter: {
                    font: {
                      size: res.data.data.nickname_parameter.font.size,
                      color: res.data.data.nickname_parameter.font.color,
                      isBold: res.data.data.nickname_parameter.font.isBold,
                    },
                    position: {
                      x: res.data.data.nickname_parameter.position.x,
                      y: res.data.data.nickname_parameter.position.y,
                    },
                  },
                  invitation_code_parameter: {
                    font: {
                      size: res.data.data.invitation_code_parameter.font.size,
                      color: res.data.data.invitation_code_parameter.font.color,
                      isBold:
                        res.data.data.invitation_code_parameter.font.isBold,
                    },
                    position: {
                      x: res.data.data.invitation_code_parameter.position.x,
                      y: res.data.data.invitation_code_parameter.position.y,
                    },
                  },
                  avatar_parameter: {
                    size: {
                      w: res.data.data.avatar_parameter.size.w,
                      h: res.data.data.avatar_parameter.size.h,
                    },
                    position: {
                      x: res.data.data.avatar_parameter.position.x,
                      y: res.data.data.avatar_parameter.position.y,
                    },
                  },
                  qr_code_parameter: {
                    size: {
                      w: res.data.data.qr_code_parameter.size.w,
                      h: res.data.data.qr_code_parameter.size.h,
                    },
                    position: {
                      x: res.data.data.qr_code_parameter.position.x,
                      y: res.data.data.qr_code_parameter.position.y,
                    },
                  },
                });

                that.color = res.data.data.invitation_code_parameter.font.color;
                that.color1 = res.data.data.nickname_parameter.font.color;
              });
            }
          });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此海报",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
            data = {
              id: num.id,
            };
            that.axios
              .get("/admin/invitationPoster/delete", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
.ant-input-number {
  width: 100%;
}
.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}
/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}
/deep/.m-colorPicker .box {
  right: 0;
}
</style>