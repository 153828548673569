var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-layout',[_c('Header'),_c('a-layout',{attrs:{"id":"components-layout-demo-custom-trigger"}},[_c('Sider'),_c('a-layout',{staticClass:"section"},[_c('a-layout-content',{style:({
            margin: '24px 16px',
            padding: '24px',
            marginTop: '24px',
            background: '#fff',
            minHeight: '280px',
          })},[_c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"海报管理"}},[_c('template',{slot:"extra"},[_c('a-button',{staticClass:"editable-add-btn",attrs:{"size":"small"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1)],2)],1),_c('a-table',{ref:"tableList",attrs:{"columns":_vm.columns,"data-source":_vm.posterList,"pagination":false,"scroll":{ x: 500, y: _vm.table_h },"row-key":function (record) { return record.id; },"size":"middle"},scopedSlots:_vm._u([{key:"btns",fn:function(btns, num){return _c('span',{},_vm._l((btns),function(btn){return _c('a-button',{key:btn,staticClass:"editable-add-btn",staticStyle:{"margin":"5px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.handel(btn, num)}}},[_vm._v(" "+_vm._s(btn)+" ")])}),1)}}])}),_c('a-modal',{attrs:{"title":_vm.modelTitle,"confirm-loading":_vm.confirmLoading,"ok-text":"确认","cancel-text":"取消"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.addform},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"海报名称："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'title',
                        {
                          rules: [
                            { required: true, message: '请输入海报名称!' } ],
                        } ]),expression:"[\n                        'title',\n                        {\n                          rules: [\n                            { required: true, message: '请输入海报名称!' },\n                          ],\n                        },\n                      ]"}]})],1),_c('a-form-item',{attrs:{"label":"排序："}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'sequence',
                        {
                          rules: [{ required: true, message: '请输入排序!' }],
                        } ]),expression:"[\n                        'sequence',\n                        {\n                          rules: [{ required: true, message: '请输入排序!' }],\n                        },\n                      ]"}]})],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("海报模板:")]),_c('a-form-item',{attrs:{"label":"图片","extra":"最多支持1张"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'template.url',
                        {
                          rules: [{ required: true, message: '请选择图片!' }],
                        } ]),expression:"[\n                        'template.url',\n                        {\n                          rules: [{ required: true, message: '请选择图片!' }],\n                        },\n                      ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file","list-type":"picture","fileList":_vm.fileList,"customRequest":_vm.changeImg,"remove":_vm.removeImg,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传图片 ")],1)],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"宽度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'template.size.w',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入宽度!',
                              } ],
                          } ]),expression:"[\n                          'template.size.w',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入宽度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"高度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'template.size.h',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入高度!',
                              } ],
                          } ]),expression:"[\n                          'template.size.h',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入高度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("邀请码:")]),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"X轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'invitation_code_parameter.position.x',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入X轴定位!',
                              } ],
                          } ]),expression:"[\n                          'invitation_code_parameter.position.x',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入X轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"Y轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'invitation_code_parameter.position.y',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入Y轴定位!',
                              } ],
                          } ]),expression:"[\n                          'invitation_code_parameter.position.y',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入Y轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"字体大小（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'invitation_code_parameter.font.size',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入字体大小!',
                              } ],
                          } ]),expression:"[\n                          'invitation_code_parameter.font.size',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入字体大小!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"颜色"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'invitation_code_parameter.font.color',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择颜色!',
                              } ],
                          } ]),expression:"[\n                          'invitation_code_parameter.font.color',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请选择颜色!',\n                              },\n                            ],\n                          },\n                        ]"}]}),_c('colorPicker',{on:{"change":_vm.headleChangeColor},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"是否加粗"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'invitation_code_parameter.font.isBold',
                          {
                            rules: [
                              { required: true, message: '请选择是否加粗!' } ],
                          } ]),expression:"[\n                          'invitation_code_parameter.font.isBold',\n                          {\n                            rules: [\n                              { required: true, message: '请选择是否加粗!' },\n                            ],\n                          },\n                        ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 加粗 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 不加粗 ")])],1)],1)],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("昵称:")]),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"X轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'nickname_parameter.position.x',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入X轴定位!',
                              } ],
                          } ]),expression:"[\n                          'nickname_parameter.position.x',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入X轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"Y轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'nickname_parameter.position.y',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入Y轴定位!',
                              } ],
                          } ]),expression:"[\n                          'nickname_parameter.position.y',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入Y轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"字体大小（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'nickname_parameter.font.size',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入字体大小!',
                              } ],
                          } ]),expression:"[\n                          'nickname_parameter.font.size',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入字体大小!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"颜色"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'nickname_parameter.font.color',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择颜色!',
                              } ],
                          } ]),expression:"[\n                          'nickname_parameter.font.color',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请选择颜色!',\n                              },\n                            ],\n                          },\n                        ]"}]}),_c('colorPicker',{on:{"change":_vm.headleChangeColor1},model:{value:(_vm.color1),callback:function ($$v) {_vm.color1=$$v},expression:"color1"}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"是否加粗"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'nickname_parameter.font.isBold',
                          {
                            rules: [
                              { required: true, message: '请选择是否加粗!' } ],
                          } ]),expression:"[\n                          'nickname_parameter.font.isBold',\n                          {\n                            rules: [\n                              { required: true, message: '请选择是否加粗!' },\n                            ],\n                          },\n                        ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 加粗 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 不加粗 ")])],1)],1)],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("二维码:")]),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"宽度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'qr_code_parameter.size.w',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入宽度!',
                              } ],
                          } ]),expression:"[\n                          'qr_code_parameter.size.w',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入宽度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"高度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'qr_code_parameter.size.h',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入高度!',
                              } ],
                          } ]),expression:"[\n                          'qr_code_parameter.size.h',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入高度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"X轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'qr_code_parameter.position.x',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入X轴定位!',
                              } ],
                          } ]),expression:"[\n                          'qr_code_parameter.position.x',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入X轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"Y轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'qr_code_parameter.position.y',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入Y轴定位!',
                              } ],
                          } ]),expression:"[\n                          'qr_code_parameter.position.y',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入Y轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{staticClass:"title",attrs:{"span":24}},[_vm._v("头像:")]),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"宽度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'avatar_parameter.size.w',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入宽度!',
                              } ],
                          } ]),expression:"[\n                          'avatar_parameter.size.w',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入宽度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"高度（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'avatar_parameter.size.h',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入高度!',
                              } ],
                          } ]),expression:"[\n                          'avatar_parameter.size.h',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入高度!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"X轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'avatar_parameter.position.x',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入X轴定位!',
                              } ],
                          } ]),expression:"[\n                          'avatar_parameter.position.x',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入X轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1),_c('a-col',{attrs:{"span":10,"offset":2}},[_c('a-form-item',{attrs:{"label":"Y轴定位（px）"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'avatar_parameter.position.y',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入Y轴定位!',
                              } ],
                          } ]),expression:"[\n                          'avatar_parameter.position.y',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入Y轴定位!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"precision":0,"step":"1","min":0}})],1)],1)],1)],1)],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }